<template>
    <section class="moduloCerca">
        <div class="cerca">

            <LazyDelayHydration v-if="filtri && results && modulo.tipologia == 'dove_mangiare'">
                <LazyFiltriDoveMangiare :filtri="filtri" :results="results" />
            </LazyDelayHydration>
            <LazyDelayHydration v-else-if="filtri && results && modulo.tipologia == 'dove_dormire'">
                <LazyFiltriDoveDormire :filtri="filtri" :results="results" />
            </LazyDelayHydration>
            <LazyDelayHydration v-else-if="filtri && results && modulo.tipologia == 'operatori_outdoor'">
                <LazyFiltriOperatori :filtri="filtri" :results="results" />
            </LazyDelayHydration>

            <transition name="fade" mode="out-in">
                <div class="largecont" v-if="dataFiltri.vediMappa && !newDataLoading">
                    <div class="vistaMappa">
                        <div class="risultati" v-if="results && results.hits && results.hits.length > 0">
                            <OggettoRisultatoRicerca v-for="hit in results.hits" :hit="hit" :key="`searchresult${hit.id}`" :luogo="dataFiltri.comune" :showimage="modulo.tipologia == 'operatori_outdoor'" />
                            <OggettoRisultatoRicerca v-for="hit in generatedCards" :hit="hit" :key="`searchresult${hit.id}`" :luogo="dataFiltri.comune" :showimage="modulo.tipologia == 'operatori_outdoor'" />
                        </div>
                        <div class="empty" v-else>
                            <div class="paragraph">{{ $t('nessun_risultato') }}</div>
                        </div>
                        <div class="mappaCont">
                            <div class="stickyMappa">
                                <RisultatiMappa :hits="[...results.hits, ...generatedCards]" :lat="dataFiltri.lat" :lng="dataFiltri.lng" />
                            </div>
                            <div class="fakeBackground"></div>
                        </div>
                    </div>
                </div>
                <div class="cont" v-else-if="!dataFiltri.vediMappa && !newDataLoading">
                    <div class="risultati" v-if="results && results.hits && results.hits.length > 0">
                        <OggettoRisultatoRicerca v-for="hit in results.hits" :hit="hit" :key="`searchresult${hit.id}`" :luogo="dataFiltri.comune" :showimage="modulo.tipologia == 'operatori_outdoor'" />
                        <OggettoRisultatoRicerca v-for="hit in generatedCards" :hit="hit" :key="`searchresult${hit.id}`" :luogo="dataFiltri.comune" :showimage="modulo.tipologia == 'operatori_outdoor'" />
                    </div>
                    <div class="empty" v-else>
                        <div class="paragraph">{{ $t('nessun_risultato') }}</div>
                    </div>
                </div>
            </transition>
            <div class="loadmore" v-if="hasMorePages">
                <NButton size="medium" type="primary" round icon-placement="right" :loading="loading" @click="loadmore">{{ $t('load_more') }}</NButton>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { NButton } from 'naive-ui'

const props = defineProps<{ modulo: any }>()

const { searchDoveMangiare, searchDoveDormire, searchOperatori } = useSearch()
const { locale, t } = useI18n()
const directus = useDirectus()
const route = useRoute()

const dataFiltri = computed(() => {
    return {
        text: route.query.text ? route.query.text as string : '',
        comune: route.query.comune ? route.query.comune as string : null,
        area: route.query.area ? route.query.area as string : null,
        tipologia: route.query.tipologia ? route.query.tipologia as string : null,
        categoria: route.query.categoria ? route.query.categoria as string : null,
        locale: route.query.locale ? route.query.locale as string : null,
        cucina: route.query.cucina ? route.query.cucina as string : null,
        prezzo: route.query.prezzo ? route.query.prezzo as string : null,
        target: route.query.target ? route.query.target as string : null,
        aderisce: route.query.aderisce ? route.query.aderisce as string : null,
        lingua: route.query.lingua ? route.query.lingua as string : null,
        lat: route.query.lat ? parseFloat(route.query.lat as string) : undefined,
        lng: route.query.lng ? parseFloat(route.query.lng as string) : undefined,
        vediMappa: route.query.mappa ? (route.query.mappa == '1' ? true : false) : false
    }
})

const page = ref(1)
const hasMorePages = ref(false)
const loading = ref(false)
const newDataLoading = ref(false)
const generatedCards = ref<any>([])

let dataKey = ''
let dataFunction = new Promise<any>(() => { return })
let filterURL = ''

const setData = () => {
    if (props.modulo.tipologia == 'dove_mangiare') {
        dataKey = `search-ristorazione-
            ${dataFiltri.value.text || '*'}-
            ${dataFiltri.value.comune || '*'}-
            ${dataFiltri.value.locale || '*'}-
            ${dataFiltri.value.cucina || '*'}-
            ${dataFiltri.value.prezzo || '*'}-
            ${dataFiltri.value.target || '*'}-
            ${dataFiltri.value.aderisce || '*'}-
            ${dataFiltri.value.lat || 'x'}-
            ${dataFiltri.value.lng || 'x'}`
        dataFunction = searchDoveMangiare(dataFiltri.value.text, dataFiltri.value.comune, dataFiltri.value.locale, dataFiltri.value.cucina, dataFiltri.value.prezzo, dataFiltri.value.target, dataFiltri.value.lat, dataFiltri.value.lng, dataFiltri.value.aderisce, page.value)
        filterURL = '/pages/content/filter/dove-mangiare'
    } else if (props.modulo.tipologia == 'dove_dormire') {
        dataKey = `search-ricettivita-
            ${dataFiltri.value.text || '*'}-
            ${dataFiltri.value.comune || '*'}-
            ${dataFiltri.value.tipologia || '*'}-
            ${dataFiltri.value.categoria || '*'}-
            ${dataFiltri.value.locale || '*'}-
            ${dataFiltri.value.cucina || '*'}-
            ${dataFiltri.value.prezzo || '*'}-
            ${dataFiltri.value.target || '*'}-
            ${dataFiltri.value.aderisce || '*'}-
            ${dataFiltri.value.lat || 'x'}-
            ${dataFiltri.value.lng || 'x'}`
        dataFunction = searchDoveDormire(dataFiltri.value.text, dataFiltri.value.comune, dataFiltri.value.tipologia, dataFiltri.value.categoria, dataFiltri.value.target, dataFiltri.value.lat, dataFiltri.value.lng, page.value)
        filterURL = '/pages/content/filter/dove-mangiare'
    } else if (props.modulo.tipologia == 'operatori_outdoor') {
        dataKey = `search-operatori-
            ${dataFiltri.value.text || '*'}-
            ${dataFiltri.value.area || '*'}-
            ${dataFiltri.value.categoria || '*'}-
            ${dataFiltri.value.lingua || '*'}`
        dataFunction = searchOperatori(dataFiltri.value.text, dataFiltri.value.area, dataFiltri.value.categoria, dataFiltri.value.lingua, page.value)
        filterURL = '/pages/content/filter/operatori'
    }
}
setData()

const { data: results, refresh: refreshResults, pending } = useAsyncData(dataKey, () => dataFunction)
const { data: filtri } = useAsyncData(`filtri-${props.modulo.tipologia}-${locale.value}`, () => directus(filterURL, { query: { locale: locale.value } }))

watch(dataFiltri, async () => {
    newDataLoading.value = true
    generatedCards.value.length = 0
    page.value = 1
    setData()
    await refreshResults()
    newDataLoading.value = false
})

if (results.value && results.value.hits) {
    hasMorePages.value = page.value < results.value.totalPages
}
watch(results, () => {
    if (results.value && results.value.hits) {
        hasMorePages.value = page.value < results.value.totalPages
    }
})

const loadmore = async () => {
    loading.value = true

    page.value = page.value += 1

    let newResults = null
    if (props.modulo.tipologia == 'dove_mangiare') {
        newResults = await searchDoveMangiare(dataFiltri.value.text, dataFiltri.value.comune, dataFiltri.value.locale, dataFiltri.value.cucina, dataFiltri.value.prezzo, dataFiltri.value.target, dataFiltri.value.lat, dataFiltri.value.lng, dataFiltri.value.aderisce, page.value)
    } else if (props.modulo.tipologia == 'dove_dormire') {
        newResults = await searchDoveDormire(dataFiltri.value.text, dataFiltri.value.comune, dataFiltri.value.tipologia, dataFiltri.value.categoria, dataFiltri.value.target, dataFiltri.value.lat, dataFiltri.value.lng, page.value)
    } else if (props.modulo.tipologia == 'operatori_outdoor') {
        newResults = await searchOperatori(dataFiltri.value.text, dataFiltri.value.area, dataFiltri.value.categoria, dataFiltri.value.lingua, page.value)
    }

    if (newResults.hits) {
        generatedCards.value.push(...newResults.hits)
        hasMorePages.value = page.value < newResults.totalPages
    }

    loading.value = false
}
</script>

<style lang="scss" scoped>
.moduloCerca {

    .risultati {
        display: flex;
        flex-direction: column;
        gap: var(--margin-gap);
        margin: var(--margin-double) 0;
    }

    .vistaMappa {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: var(--margin-default);
    }

    .mappaCont {
        margin: var(--margin-double) 0;

        .fakeBackground {
            display: none;
        }

        .stickyMappa {
            position: sticky;
            top: var(--margin-double);

            border-radius: var(--size-card-radius);
            overflow: hidden;
        }
    }

    .empty {
        margin: var(--margin-double) 0;
    }
}

.loadmore {
    margin: var(--margin-double) 0;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .moduloCerca {
        .vistaMappa {
            position: relative;
            display: block;

            .risultati {
                padding-top: 340px
            }

            .mappaCont {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin-top: 0;
                pointer-events: none;

                .stickyMappa {
                    top: var(--margin-page);
                    pointer-events: auto;
                    z-index: 2;
                }

                .fakeBackground {
                    display: block;
                    position: sticky;
                    top: 0;
                    height: 40px;
                    background-color: var(--color-background-page);
                    width: 100%;
                    z-index: 1;
                }
            }
        }
    }
}
</style>